var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full" },
          [
            _c(
              "vs-button",
              { staticClass: "mr-4 sm:mb-0 mb-2", on: { click: _vm.onAdd } },
              [_vm._v("Add")]
            ),
          ],
          1
        ),
      ]),
      _c("ag-grid-vue", {
        ref: "agGridTable",
        staticClass:
          "ag-theme-material w-100 ag-grid-table dynamic-height-supplier-settings-grid my-4",
        attrs: {
          components: _vm.cellRendererComponents,
          columnDefs: _vm.columnDefs,
          defaultColDef: _vm.defaultColDef,
          rowData: _vm.settingsData,
          gridOptions: _vm.gridOptions,
          rowSelection: "single",
          pagination: true,
          paginationPageSize: _vm.paginationPageSize,
          suppressPaginationPanel: true,
        },
        on: { "selection-changed": _vm.onSelectionChanged },
      }),
      _c("vs-pagination", {
        attrs: { total: _vm.totalPages, max: 5 },
        model: {
          value: _vm.currentPage,
          callback: function ($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }